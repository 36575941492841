<template>
  <b-card>
    <b-row>
      <b-col>
        <b-card
          no-body
          class="border mt-1"
        >
          <b-card-header class="p-1">
            <b-card-title class="font-medium-2">
              <feather-icon
                icon="UserIcon"
                size="18"
              />
              <span class="align-middle ml-50">{{ $t('Profile') }}</span>
            </b-card-title>
          </b-card-header>
          <b-row>
            <b-col
              md="6"
              sm="12"
            >
              <b-list-group>
                <b-list-group-item
                  v-for="(list) in listLeft"
                  :key="list.key"
                >
                  <div class="d-flex justify-content-between">
                    <div class="title badge badge-primary">
                      {{ $t(list.key) }}
                    </div>
                    <div>{{ list.value }}</div>
                  </div>
                </b-list-group-item>
              </b-list-group>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <!-- PERMISSION TABLE -->
        <b-card
          no-body
          class="border mt-1"
        >
          <b-card-header class="p-1">
            <b-card-title class="font-medium-2">
              <feather-icon
                icon="LockIcon"
                size="18"
              />
              <span class="align-middle ml-50">{{ $t('Permission') }}</span>
            </b-card-title>
          </b-card-header>
          <b-table
            striped
            responsive
            class="mb-0"
            :items="permissionsDataTemp"
          >
            <template #cell(module)="data">
              {{ $t(`PERMISSION_${data.value}`) }}
            </template>
            <template #cell()="data">
              <b-form-checkbox
                v-if="!permissionFormDataObj[data.item.module]['disabled'].includes(data.field.key)"
                disabled
                :checked="permissionFormDataObj[data.item.module][data.field.key]"
              />
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import store from '@/store'
import { DecimalFormat } from '@/utils/number'
import { GetAgentGroupType } from '@/utils/agent'

export default {
  data() {
    return {
      permissionFormDataObj: {
        Dashboard: {
          read: false, update: false, create: false, delete: false, disabled: ['delete', 'update', 'create'],
        },
        Accounts: {
          read: false, update: false, create: false, delete: false, disabled: ['delete'],
        },
        Members: {
          read: false, update: false, create: false, delete: false, disabled: ['delete'],
        },
        SubAccounts: {
          read: false, update: false, create: false, delete: false, disabled: ['delete'],
        },
        UpdateCreditAgent: {
          read: false, update: false, create: false, delete: false, disabled: ['delete', 'create'],
        },
        UpdateCreditMember: {
          read: false, update: false, create: false, delete: false, disabled: ['delete', 'create'],
        },
        Reports: {
          read: false, update: false, create: false, delete: false, disabled: ['delete', 'update', 'create'],
        },
        LogAgentTransactions: {
          read: false, update: false, create: false, delete: false, disabled: ['delete', 'update', 'create'],
        },
        LogMemberTransactions: {
          read: false, update: false, create: false, delete: false, disabled: ['delete', 'update', 'create'],
        },
        Billing: {
          read: false, update: false, create: false, delete: false, disabled: ['delete', 'create'],
        },
        AutoPartner: {
          read: false, update: false, create: false, delete: false, disabled: ['delete'],
        },
        PartnersKeys: {
          read: false, update: false, create: false, delete: false, disabled: [],
        },
      },
    }
  },
  computed: {
    userData() {
      return store.getters['auth/user']
    },
    userInfo() {
      return store.getters['auth/userInfo']
    },
    loggedIn() {
      return store.getters['auth/loggedIn']
    },
    listLeft() {
      return [
        { key: 'Username', value: this.userData.username },
        { key: 'Level', value: this.userData.type },
        { key: 'Level Group', value: GetAgentGroupType(this.userData) },
        { key: 'Currency', value: this.userInfo.currency },
        { key: 'Credit', value: DecimalFormat(this.userInfo.creditLimit) },
        { key: 'Balance', value: DecimalFormat(this.userInfo.balance) },
        { key: 'Max Credit', value: DecimalFormat(this.userInfo.maxCreditLimit) },
        { key: 'Outstanding', value: 'N/A' },
        { key: 'Total Downline', value: this.userInfo.downline_total },
        { key: 'Total Member', value: this.userInfo.downline_total },
        { key: 'Total Downline Credit', value: "N/A" },
        { key: 'Total Member Credit', value: "N/A" },
      ]
    },
    permissionsDataTemp() {
      const keys = Object.keys(this.permissionFormDataObj)
      const permissions = []
      for (let index = 0; index < keys.length; index += 1) {
        const key = keys[index];
        permissions.push({
          module: key,
          read: this.permissionFormDataObj[key].read,
          update: this.permissionFormDataObj[key].update,
          create: this.permissionFormDataObj[key].create,
          delete: this.permissionFormDataObj[key].delete,
        })
      }
      return permissions
    },
  },
  mounted() {
    this.setPermissions()
  },
  methods: {
    setPermissions() {
      const userPermissions = this.userData.permissions
      const permissionFormDataObjKeys = Object.keys(this.permissionFormDataObj)

      if (this.userData.type !== 'SUB') {
        for (let index = 0; index < permissionFormDataObjKeys.length; index += 1) {
          const subject = permissionFormDataObjKeys[index];
          this.permissionFormDataObj[subject].read = true
          this.permissionFormDataObj[subject].update = true
          this.permissionFormDataObj[subject].create = true
          this.permissionFormDataObj[subject].delete = true
        }
        return
      }

      for (let index = 0; index < userPermissions.length; index += 1) {
        const userPermission = userPermissions[index];
        // from server
        // manage
        // create
        // read
        // update
        // delete
        const { subject } = userPermission
        const { action } = userPermission
        if (permissionFormDataObjKeys.includes(subject)) {
          this.permissionFormDataObj[subject][action] = true
        }
      }
    },
  },
}
</script>
